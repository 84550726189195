<template>
  <el-scrollbar :native="false" style="margin-top: 34px">
    <div style="max-height: 100vh;">
      <el-row type="flex">
        <el-col :span="12" :offset="6">
          <PageHeader :title="__('Upload Data')"></PageHeader>
        </el-col>
      </el-row>

      <el-form
        label-position="top"
        ref="uploadDataForm"
        :model="form"
        label-width="100px"
        :rules="rules"
        v-loading="isSubmitting"
        class="form-container"
      >
        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <el-row>
              <el-col>
                <el-form-item>
                  <el-button @click="downloadTemplate"
                    >{{ __("Download template") }}
                  </el-button>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-form-item>
                  <el-button @click="downloadUploadedData"
                    >{{ __("Download Current") }}
                    <span v-if="!isChatBot">{{ __("Dialing") }}</span
                    ><span v-else>{{ __("Chat") }}</span> {{ __("List") }}
                  </el-button>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-form-item
                  prop="file_path"
                  :label="
                    __('Upload Campaign :cname :lname List', {
                      cname: campaign.campaign_name,
                      lname: labelName
                    })
                  "
                ></el-form-item>
                <csv-uploader
                  :file-name="file_name"
                  :file-url="file_url"
                  folder="temp"
                  @on-success="handleSuccess"
                  @on-delete="handleDelete"
                  @on-progress="isLoading = true"
                  @on-error="handleError"
                />
              </el-col>
            </el-row>
            <el-row class="pt-10">
              <el-col>
                <el-form-item prop="insert_type">
                  <el-radio-group
                    style="display: flex; flex-direction: column"
                    v-model="form.insert_type"
                  >
                    <el-radio label="append" class="pt-5"
                      >{{
                        __("Append to existing :lname list", {
                          lname: labelName.toLowerCase().trim()
                        })
                      }}
                    </el-radio>
                    <el-radio label="replace" class="pt-5"
                      >{{
                        __("Replace existing :lname list", {
                          lname: labelName.toLowerCase().trim()
                        })
                      }}
                    </el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12">
                <el-button
                  :disabled="isLoading"
                  class="submitBtn"
                  @click="submit"
                  >{{ __("Submit") }}
                </el-button>
                <el-button class="cancelBtn" @click="handleCancel()"
                  >{{ __("Cancel") }}
                </el-button>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </el-scrollbar>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import { TASK_TYPES } from "@/constants/nodes";
import {
  getListBasedCampaignTemplate,
  getListBasedCampaignUploadedData,
  uploadCampaignCallList
} from "@/api/campaigns";
import FileDownloader from "@/mixins/FileDownloader";
import { removeFiles } from "@/api/services";
import CsvUploader from "@/components/uploaders/types/CsvUploader";

export default {
  name: "uploadData",
  mixins: [FileDownloader],
  components: {
    CsvUploader,
    PageHeader
  },
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {
        file_path: "",
        insert_type: "append"
      },
      file_url: "",
      file_name: "",
      isLoading: false, // to disable submit button while the upload is in progress
      isSubmitting: false,
      uploadedFilePaths: [],
      rules: {
        file_path: {
          required: true,
          message: __("Please upload a file")
        }
      }
    };
  },
  methods: {
    downloadTemplate() {
      getListBasedCampaignTemplate(this.campaign).then(response => {
        this.downloadFile(
          response.data,
          `campaign_${this.campaign.campaign_name}_csv_data.csv`
        );
      });
    },
    downloadUploadedData() {
      getListBasedCampaignUploadedData(this.campaign).then(response => {
        this.downloadFile(
          response.data,
          `campaign_${this.campaign.campaign_name}_csv_uploaded_data.csv`
        );
      });
    },
    handleError() {
      this.isLoading = false;
    },
    handleSuccess(url) {
      this.isLoading = false;
      this.uploadedFilePaths.push(url.path);
      this.form.file_path = url.path;
      this.file_url = url.url;
      this.file_name = url.uploaded_file_name;
      this.$refs.uploadDataForm.validateField("file_path");
    },
    handleCancel() {
      this.$emit("cancel");
    },
    handleDelete() {
      this.resetForm();
    },
    resetForm() {
      this.form.file_path = "";
      this.file_url = "";
      this.file_name = "";
      this.form.insert_type = "append";
    },
    removeUploadedFiles() {
      removeFiles(this.uploadedFilePaths);
    },
    submit() {
      this.$refs.uploadDataForm.validate(valid => {
        if (valid) {
          this.isSubmitting = true;
          uploadCampaignCallList(this.campaign, this.form)
            .then(({ data }) => {
              this.$message({
                message: data.message,
                type: "info"
              });
              this.$emit("cancel");
            })
            .catch(err => {
              this.$message({
                message: err.message,
                type: "error"
              });
            })
            .finally(() => {
              this.isSubmitting = false;
            });
        }
      });
    },
    isChatBot() {
      return this.campaign.campaign_channel === TASK_TYPES.CHATBOT;
    }
  },
  computed: {
    labelName() {
      return this.campaign.campaign_channel === TASK_TYPES.CHATBOT
        ? __("Passed Parameters")
        : __("Call");
    }
  },
  beforeDestroy() {
    this.removeUploadedFiles();
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/button.scss";

.pt-10 {
  padding-top: 10px;
}

.pt-5 {
  padding-top: 5px;
}
</style>
